<template>
  <WeContainer header="ture" bread-crumb="" v-bind:actions="actions">
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:showOnLoad="checkFilterStorage()"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- First Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="first_name"
                label="Ad"
                placeholder="Ad"
                v-model="filter.first_name"
              />
            </div>
            <!-- First Name -->

            <!-- Last Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="last_name"
                label="Soyad"
                placeholder="Soyad"
                v-model="filter.last_name"
              />
            </div>
            <!-- Last Name -->

            <!-- Gender -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="gender"
                label="Cinsiyet"
                placeholder="Cinsiyet"
                v-bind:option-prop="genders"
                v-model="filter.gender"
              />
            </div>
            <!-- Gender -->

            <!-- TCN -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="tcn"
                label="T.C Kimlik No"
                placeholder="T.C Kimlik No"
                v-model="filter.tcn"
                v-bind:filter-number="true"
              />
            </div>
            <!-- TCN -->

            <!-- VKN -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="vkn"
                label="Vergi Numarası"
                placeholder="Vergi Numarası"
                v-model="filter.vkn"
                v-bind:filter-number="true"
              />
            </div>
            <!-- VKN -->

            <!-- Phone -->
            <!-- LocalStorage'de bulunan filtreleme verisini phone içerisine atabilmek için WeInput yerine direkt WePhone kullanılmıştır. -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label class="custom-label">Cep Telefonu</label>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group mb-0">
                    <WePhone
                      placeholder="Cep Telefonu"
                      v-model="filter.phone"
                      class="input-group input-group-lg"
                      v-bind:wrapperClass="'phone-mask-wrapper-lib-full'"
                    />
                  </div>
                </div>
              </div>
              <!-- Phone -->
            </div>
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Email -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="email"
                label="E-Posta Adresi"
                placeholder="E-Posta Adresi"
                v-model="filter.email"
              />
            </div>
            <!-- Email -->

            <!-- Birthdate -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Doğum Tarihi"
                placeholder="Doğum Tarihi"
                v-bind:date-range="true"
                v-model="filter.birthdate"
              />
            </div>
            <!-- Birthdate -->

            <!-- Member Group -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="member_group"
                label="Üye Grubu"
                placeholder="Üye Grubu"
                v-bind:option-prop="memberGroups"
                v-model="filter.member_group"
              />
            </div>
            <!-- Member Group -->

            <!-- Current Code -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="current_code"
                label="Cari Hesap Kodu"
                placeholder="Cari Hesap Kodu"
                v-model="filter.current_code"
              />
            </div>
            <!-- Current Code -->

            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Üyelik Tarihi"
                placeholder="Üyelik Tarihi"
                v-bind:date-range="true"
                v-model="filter.created_at"
              />
            </div>
            <!-- Created At -->

            <!-- Durum -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="is_active"
                label="Durum"
                placeholder="Durum"
                v-bind:clearable="true"
                v-bind:option-prop="statusOptions"
                v-model="filter.is_active"
              />
            </div>
            <!-- Durum -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeTable
      v-bind:index="false"
      v-bind:data="member.list"
      v-bind:allowSortIndex="false"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:exportables="exportables"
      v-bind:ajax="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:on-export="onExport"
      v-on:make-request="updateMemberList"
      v-bind:componentName="componentName"
      ref="memberTable"
    ></WeTable>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      componentName: "memberlist",
      loading: false,
      exportables: ["excel"],
      actions: [
        {
          text: "Yeni Müşteri",
          path: "members/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-right",
          icon: "fas fa-trash",
          path: "members/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "full_name", th: "Müşteri Adı Soyadı", type: "string" },
        { name: "email", th: "E-Posta", type: "string" },
        { name: "current_code", th: "Cari Hesap Kodu", type: "string" },
        { name: "member_type", th: "Üye Tipi", type: "string" },
        { name: "member_group", th: "Üye Grubu", type: "string" },
        { name: "created_at", th: "Üyelik Tarihi", type: "datetime" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      genders: [
        {
          id: "E",
          name: "Erkek",
        },
        {
          id: "K",
          name: "Kadın",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
      copyFilter: {},
      filter: {
        first_name: null,
        last_name: null,
        phone: null,
        gender: null,
        tcn: null,
        vkn: null,
        member_group: null,
        email: null,
        current_code: null,
        is_active: null,
        birthdate: null,
        created_at: null,
      },
    };
  },
  methods: {
    ...mapActions("member", ["getList", "delete", "isActive"]),
    ...mapActions("tableExport", ["exportData"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    checkFilterStorage() {
      return helper.checkFilterStorage(this.componentName);
    },
    onEdit(row) {
      this.$router.replace("members/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.full_name} müşterisini silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Müşteri Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Müşteri Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    onExport(data) {
      if (this.member.list && this.member.list.length) {
        if (data.name !== "print") {
          this.exportData({
            route: "export-list/members",
            exportable: data,
            fileName: "Müşteri Listesi",
            filter: this.copyFilter,
          });
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.memberTable.currentPage = 1;
      helper.addCacheCurrentFilter(this.copyFilter, this.componentName);
      this.updateMemberList();
    },
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      helper.removeCacheCurrentFilter(this.componentName);
      this.updateMemberList();
    },
    updateMemberList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      var localFilter = localStorage.getItem(
        `last_${this.componentName}_filter`
      );
      this.filter = helper.loadLocalFilter(this.filter, localFilter);
      this.copyFilter = helper.clone(this.filter);

      this.loading = true;
      this.getList({
        filter: this.copyFilter,
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("member", code);
    },
  },
  computed: {
    ...mapState({
      member: (state) => state.member,
      memberGroups: (state) => state.membergroup.list,
    }),
    // exportData() {
    //   const item = {};
    //   item.outputName = "members";
    //   item.data = this.member.list;

    //   return item;
    // },
  },
  mounted() {
    this.$store.dispatch("membergroup/getList"); // Get Member Groups
    //this.updateMemberList();
  },
};
</script>

